import { Controller } from "@hotwired/stimulus";

export default class PortalNav extends Controller {
	toggleMenu() {
		this.element.querySelector( '.extraMenuBlock' )?.classList?.toggle( 'open' );
	}

	showExpandedMenu( e ) {
		console.log( e );
		const newTarget = e.target.dataset.target;
		const openMenus = this.element.querySelectorAll( '.extraLinks.open' );
		for( let i = 0; i < openMenus.length; i++ ) {
			openMenus[ i ].classList.remove( 'open' );
		}

		this.element.querySelector( '.extraLinks.' + newTarget )?.classList?.add( 'open' );
	}
}
