import { Controller } from "@hotwired/stimulus";
import select2 from 'select2';
import Chart from 'chart.js/auto';

export default class Tonnage extends Controller {
	static values = {
		// Passed in from the HTML
		defaultDealerId: String
	};

	static targets = [ 'goal', 'goalBlock', 'goalFormSubmit' ];

	all = 'all';
	colors = [
		{ background: 'transparent', border: '#29368C', point: 'rgb(41, 54, 140)' },
		{ background: 'transparent', border: 'rgba(252, 182, 20, 0.6', point: '#FCB614' },
		{ background: 'transparent', border: 'rgba(119, 65, 127, 0.32)', point: '#77417F' },
		{ background: 'transparent', border: 'rgba(149, 218, 212, 0.32)', point: '#95DAD4' },
		{ background: 'transparent', border: 'rgba(50, 159, 227, 0.32)', point: '#329FE3' },
		{ background: 'transparent', border: 'rgba(216, 101, 143, 0.32)', point: '#D8658F' },
		{ background: 'transparent', border: 'rgba(175, 175, 175, 0.5)', point: '#DEDEDE' },
	];
	months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	];

	dataPoints = null;
	dealers = null;
	selectedDealers = null;
	toDate = null;
	fromDate = null;
	toYear = null;
	toMonth = null;
	fromYear = null;
	fromMonth = null;
	fromDateArray = null;

	$dealerSelect = null;
	categoriesGraph = null;
	yearlyTotalsGraph = null;
	monthlyTotalsGraph = null;

	connect() {
		this.onRangeSelect( true );

		this.$dealerSelect = $( '#dealerSelect' );
		this.$dealerSelect.val( this.defaultDealerIdValue );
		this.$dealerSelect.select2();
		this.$dealerSelect.on( 'select2:select', this.onDealerChange.bind(this) );

		// Default to the first dealers in the list (all dealers is a bit too slow for default)
		this.onDealerChange({ params:{data:{id: this.defaultDealerIdValue}} });
	}

	disconnect() {
		// This is probably superfluous but we will clean up some graphs just in case
		this.$dealerSelect?.off( 'select2:select' );

		this.categoriesGraph?.destroy();
		this.categoriesGraph = null;

		this.yearlyTotalsGraph?.destroy();
		this.yearlyTotalsGraph = null;

		this.monthlyTotalsGraph?.destroy();
		this.monthlyTotalsGraph = null;
	}

	openTab( e ) {
		let $tab = $( e.currentTarget );
		let $content = $( e.params.id ).first();
		let showDates = e.params.id == '#categoriesGraph';

		$tab.siblings( '.active' ).removeClass( 'active' );
		$tab.addClass( 'active' );
		$content.siblings( '.active' ).removeClass( 'active' );
		$content.addClass( 'active' );
		$( '.tabs.charts .dates' ).toggle( showDates );
	}

	onRangeSelect( skipDataLoad ) {
		const currentYear = new Date().getFullYear();
		const toYear =    parseInt( document.getElementById( 'toYear' )?.value || currentYear );
		const toMonth =   parseInt( document.getElementById( 'toMonth' )?.value || '12' );
		const fromYear =  parseInt( document.getElementById( 'fromYear' )?.value || currentYear );
		const fromMonth = parseInt( document.getElementById( 'fromMonth' )?.value || '01' );

		const rangeChanged =
			this.toYear != toYear ||
			this.toMonth != toMonth ||
			this.fromYear != fromYear ||
			this.fromMonth != fromMonth;

		this.toYear = toYear;
		this.toMonth = toMonth;
		this.fromYear = fromYear;
		this.fromMonth = fromMonth;

		this.toDate = this.toMonth.toString().padStart(2, '0') + '/' + this.toYear;
		this.fromDate = this.fromMonth.toString().padStart(2, '0') + '/' + this.fromYear;

		$( 'input.toDate' ).val( this.toDate );
		$( 'input.fromDate' ).val( this.fromDate );

		document.querySelector( '.dateRangeScreen' )?.classList?.remove( 'open' );

		if( rangeChanged && skipDataLoad !== true ) {
			// Only request new data on date changes, since it's a relatively heavy request
			this.rebuildPrintLink();

			this.rewriteData();
		}
	}

	showDateRange() {
		document.querySelector( '.dateRangeScreen' )?.classList?.add( 'open' );
	}

	onDealerChange( e ) {
		if( e.params && e.params.data ) {
			let urlParams = '';
			let dealerId = e.params.data.id;

			this.selectedDealers = dealerId;
			if( dealerId == this.all ) {
				this.selectedDealers = this.all;
			} else if( dealerId ) {
				urlParams = '?dealer=' + dealerId;
			}

			this.rebuildPrintLink();

			$( '.portal .tonnage' ).removeClass( 'loaded' );
			$.ajax( '/portal/tonnage/stats' + urlParams, {
				method: 'GET'
			} )
			.done( this.onTonnageLoad.bind(this) );
		}
	}

	onTonnageLoad( data ) {
		this.dataPoints = data.tonnage;
		this.dealers = data.dealers;

		this.rewriteData();

		$( '.portal .tonnage' ).addClass( 'loaded' );
	}

	rewriteData() {
		// Fill out dealer data
		this.populateDealerInfo();

		// Build out the graphs
		this.buildYearlyTotalsGraph();
		this.buildYearOverYearGraph();
		this.buildCategoriesGraph();

		// Build the tables
		this.buildTonnageTable();
		this.buildCategoriesTable();
		this.buildBonusesTable();
	}

	rebuildPrintLink() {
		var params = [
			'v=tonnage',
			'printView=1',
			'print=' + this.selectedDealers,
			'from=' + this.fromYear + '-' + this.fromMonth,
			'to=' + this.toYear + '-' + this.toMonth,
		]
		var path = window.location.pathname + '?' + params.join('&');

		$( '#print' ).html(
			'<a id="print" target="_blank" href="' + path + '">' +
				'Printable Version' +
			'</a>'
		);
	}

	cleanFloat( float, precisionOveride ) {
		var precision = precisionOveride || 100;

		return Math.round( float * precision ) / precision;
	}

	selectParentDealer() {
		var dealer = this.dealers[ 0 ];

		for( var i = 0; i < this.dealers.length; i++ ) {
			if( !this.dealers[i].parent_dealer_id ) {
				dealer = this.dealers[ i ];
				break;
			}
		}

		return dealer;
	}

	populateDealerInfo() {
		var dealer = this.selectParentDealer();

		// Default Values (some will change below for ALL Dealers)
		var name = dealer.name;
		var pg_dealer_id = dealer.pg_dealer_id;
		var goalTons = dealer.goal_tons;
		var ytdTons = dealer.ytd_tons;
		var lastYtdTons = dealer.last_ytd_tons;
		var lastYearTons = dealer.last_year_total_tons;

		// All Dealers Corrections
		if( this.selectedDealers == this.all ) {
			$( '#loyalty' ).hide();

			name = 'All Dealers';
			pg_dealer_id = this.dealers.length + ' Dealers';
			for( var i = 1; i < this.dealers.length; i++ ) {
				var dealer = this.dealers[ i ];
				goalTons += dealer.goal_tons;
				ytdTons += dealer.ytd_tons;
				lastYtdTons += dealer.last_ytd_tons;
				lastYearTons += dealer.last_year_total_tons;
			}
		} else {
			$( '#loyalty' ).show();
		}
		this.cleanFloat( goalTons, 10 );

		var ytdDeltaSwing = '';
		var ytdDelta = this.cleanFloat( ytdTons - lastYtdTons, 10 );
		var ytdDeltaPct = this.cleanFloat((100 * ytdTons / lastYtdTons) - 100, 10) || 0;
		if( ytdTons < lastYtdTons ) {
			if( Math.abs(ytdTons - lastYtdTons) > lastYtdTons * 0.05 ) {
				ytdDeltaSwing = 'down'
			}
		} else {
			ytdDelta = '+' + ytdDelta;
			ytdDeltaPct = '+' + ytdDeltaPct;

			if( Math.abs(ytdTons - lastYtdTons) > lastYtdTons * 0.05) {
				ytdDeltaSwing = 'up';
			}
		}

		// Dealer details
		$( '#dealerName' ).html( name );
		$( '#dealerId' ).html( pg_dealer_id );
		$( '#goal' ).html( goalTons );

		// Goal Tonnage Form
		$( '#tonnageGoalForm #dealer_goal_tonnage' ).val( goalTons );
		$( '#tonnageGoalForm #dealer_pg_dealer_id' ).val( pg_dealer_id );

		// Datablocks
		$( '#YTDTons' ).html(
			this.cleanFloat(ytdTons, 10) +
			'<span class="delta ' + ytdDeltaSwing + '">' +
				ytdDelta + 't' +
				'<br />' +
				ytdDeltaPct + '%' +
			'<span>'
		);
		$( '#lastYTDTons' ).html( this.cleanFloat(lastYtdTons, 10) );
		$( '#lastYearTotalTons' ).html( this.cleanFloat(lastYearTons, 10) );
	}

	buildYearlyTotalsGraph() {
		var years = [];
		var totals = [];

		for( var i = 0; i < this.dataPoints.length; i++ ) {
			var datapoint = this.dataPoints[ i ];

			var year = datapoint.date.replace(/-\d+/, '');
			var index = years.indexOf( year );
			if( index < 0 ) {
				years.push( year );
				index = years.length - 1;
			}

			if( totals.length <= index ) {
				totals.push( 0 );
			}

			totals[ index ] += datapoint.tons;
		}

		// Clean up horrible floating point values, leaving 1 decimal of precision
		var dataMax = 50;
		for( var i = 0; i < totals.length; i++ ) {
			totals[ i ] = Math.round( totals[i] * 10 ) / 10;
			if( dataMax && totals[i] > dataMax ) {
				dataMax = undefined;
			}
		}

		if( this.yearlyTotalsGraph ) {
			this.yearlyTotalsGraph.clear();
			this.yearlyTotalsGraph.destroy();
		}

		var ctx = document.getElementById( 'yearlyTotalsGraph' );
		if( window.outerWidth < 800 ) {
			ctx.setAttribute( 'height', 500 );
		}

		this.yearlyTotalsGraph = new Chart( ctx, {
			type: 'line',
			data: {
				labels: years,
				datasets: [{
					label: 'Total Tonnage',
					data: totals,
					borderWidth: 3,
					pointRadius: 4,
					borderColor: this.colors[ 0 ].border,
					backgroundColor: this.colors[ 0 ].background,
					pointBackgroundColor: this.colors[ 0 ].point,
				}]
			},
			options: {
				legend: {
					labels: {
						boxWidth: 7,
						padding: 20,
						usePointStyle: true
					}
				},
				scales: {
					y: {
						ticks: {
							min: 0,
							max: dataMax
						}
					}
				}
			}
		} );
	}

	buildYearOverYearGraph() {
		var currentYear = (new Date()).getFullYear();
		var totals = {};
		totals[ currentYear + ' Goal' ] = [];

		// Populate the goal values
		var yearGoal = 0;
		for( var i = 0; i < this.dealers.length; i++ ) {
			yearGoal += this.dealers[ i ].goal_tons;
		}
		for( var i = 0; i < 12; i++ ) {
			totals[ currentYear + ' Goal' ].push( yearGoal / 12 );
		}

		for( var i = 0; i < this.dataPoints.length; i++ ) {
			var datapoint = this.dataPoints[ i ];

			var year = parseInt( datapoint.date.split('-')[0] );
			var month = parseInt( datapoint.date.split('-')[1] );

			if( !totals[year] ) {
				totals[ year ] = [];
			}

			if( totals[year].length >= month ) {
				totals[ year ][ month - 1 ] += datapoint.tons;
			} else {
				totals[ year ].push( datapoint.tons );
			}
		}

		// Clean up horrible floating point values, leaving 1 decimal of precision
		var dataMax = 50;
		for( var year in totals ) {
			for( var i = 0; i < totals[year].length; i++ ) {
				totals[ year ][ i ] = Math.round( totals[year][i] * 10 ) / 10;
				if( dataMax && totals[year][i] > dataMax ) {
					dataMax = undefined;
				}
			}
		}

		// If the current month has 0 data, hide it
		if( totals[currentYear] && totals[currentYear][totals[currentYear].length - 1] == 0 ) {
			totals[ currentYear ].splice( -1, 1 );
		}

		if( this.monthlyTotalsGraph ) {
			this.monthlyTotalsGraph.destroy();
		}

		var index = 0;
		var datasets = [];
		for( var year in totals ) {
			index = currentYear - year;
			var colors = this.colors[ 0 ];
			if( index < this.colors.length  ) {
				colors = this.colors[ index ];
			} else {
				colors = this.colors[ this.colors.length - 1 ];
			}

			datasets.push( {
				label: year,
				data: totals[ year ],
				backgroundColor: colors.background,
				borderColor: colors.border,
				borderWidth: 4,
				pointRadius: 4,
				order: (index + 1) || 0,
				hidden: index == NaN || index > 1,
				pointBackgroundColor: colors.point,
			} );
		}

		var ctx = document.getElementById( 'monthlyTotalsGraph' );
		if( window.outerWidth < 800 ) {
			ctx.setAttribute( 'height', 500 );
		}
		this.monthlyTotalsGraph = new Chart(ctx, {
			type: 'line',
			data: {
				labels: this.months,
				datasets: datasets
			},
			options: {
				legend: {
					labels: {
						boxWidth: 7,
						padding: 20,
						usePointStyle: true
					}
				},
				scales: {
					y: {
						ticks: {
							min: 0,
							max: dataMax
						}
					}
				}
			}
		})
	}

	monthsBetween( fromDate, toDate ) {
		fromDate = fromDate.split( '-' );
		toDate = toDate.split( '-' );
		var years = toDate[0] - fromDate[0] + 1;

		return (12 * years) - (fromDate[1] - 1) - (12 - toDate[1]);
	}

	buildTonnageTable() {
		var tableData = [];

		var fromDate = this.convertTonangeDate( this.fromDate );
		var toDate = this.convertTonangeDate( this.toDate );

		var months = this.monthsBetween( fromDate, toDate );

		// Move all of the data into a table-like format
		for( var i = 0; i < this.dataPoints.length; i++ ) {
			var datapoint = this.dataPoints[ i ];

			if( !this.inDateRange(datapoint.date) ) {
				continue;
			}

			// Find the row matching this datapoint's Dealer
			var rowIndex = -1;
			for( var j = 0; j < tableData.length; j++ ) {
				if( tableData[j][0] == datapoint.dealer ) {
					rowIndex = j;
					break;
				}
			}

			if( rowIndex < 0 ) {
				rowIndex = tableData.push( [] ) - 1;
				tableData[ rowIndex ].push( datapoint.dealer );
				while( tableData[rowIndex].length < (months + 2) ) {
					tableData[ rowIndex ].push( 0 );
				}
			}

			var monthIndex = this.monthsBetween( fromDate, datapoint.date );

			var rounded = this.cleanFloat( datapoint.tons );
			tableData[ rowIndex ][ monthIndex ] += rounded;
			tableData[ rowIndex ][ tableData[rowIndex].length - 1 ] += datapoint.tons;
		}

		var tableHead = '';
		var startingMonth = parseInt( fromDate.split('-')[1] ) - 1;
		var startingYear = parseInt( fromDate.split('-')[0] );
		let columns = [ '<th class="category">Dealer Name</th>' ];
		for( var i = 0; i < months; i++ ) {
			var yearLabel = startingYear + parseInt( (startingMonth + i) / 12 );
			columns.push(
				'<th>' +
					this.months[(startingMonth + i) % 12] +
					'<span class="year">' + yearLabel + '</span>' +
				'</th>'
			);
		}
		columns.push( '<th class="total">Total</th>' );

		$( '#monthlyTotalsTable table' ).html( '<tr>' + columns.join('') +'</tr>' );

		var tableHtml = '';
		for( var i = 0; i < tableData.length; i++ ) {
			var row = tableData[ i ];

			var cells = [];
			cells.push( '<td class="cell category">' + row[0] + '</td>' );
			for( var j = 1; j < row.length - 1; j++ ) {
				cells.push( '<td class="cell">' + this.cleanFloat(row[j], 100) + '</td>' );
			}
			var rounded = Math.round( row[row.length - 1] * 10 ) / 10;
			cells.push( '<td class="cell total">' + rounded + '</td>' );

			var rowClass = i % 2 == 0 ? 'even' : 'odd';
			tableHtml += '<tr class="' + rowClass + '">' + cells.join('') + '</tr>';
		}

		$( '#monthlyTotalsTable table' ).append( tableHtml );
	}

	convertTonangeDate( date ) {
		return date.split('/')[ 1 ] + '-' + date.split('/')[ 0 ];
	}

	inDateRange( tonnageDate ) {
		var dateYear  = parseInt( tonnageDate.split('-')[0] );
		var dateMonth = parseInt( tonnageDate.split('-')[1] );

		var result = (
			( dateYear > this.fromYear || (dateYear == this.fromYear && dateMonth >= this.fromMonth) )
			&&
			( dateYear < this.toYear || (dateYear == this.toYear && dateMonth <= this.toMonth) )
		);

		return result;
	}

	buildCategoriesTable() {
		var tableData = [];

		var fromDate = this.convertTonangeDate( this.fromDate );
		var toDate = this.convertTonangeDate( this.toDate );
		var months = this.monthsBetween( fromDate, toDate );

		// Move all of the data into a table-like format
		for( var i = 0; i < this.dataPoints.length; i++ ) {
			var datapoint = this.dataPoints[ i ];

			if( !this.inDateRange(datapoint.date) ) {
				continue;
			}

			// Find the row matching this datapoint's category
			var rowIndex = -1;
			for( var j = 0; j < tableData.length; j++ ) {
				if( tableData[j][0] == datapoint.category ) {
					rowIndex = j;
					break;
				}
			}

			if( rowIndex < 0 ) {
				rowIndex = tableData.push( [] ) - 1;
				tableData[ rowIndex ].push( datapoint.category )
				while( tableData[rowIndex].length < (months + 2) ) {
					tableData[ rowIndex ].push( 0 );
				}
			}

			var monthIndex = this.monthsBetween( fromDate, datapoint.date );

			var rounded = this.cleanFloat( datapoint.tons );
			tableData[ rowIndex ][ monthIndex ] += rounded;
			tableData[ rowIndex ][ tableData[rowIndex].length - 1 ] += datapoint.tons;
		}

		// Explicitly sort by category name
		tableData.sort( function(a, b) {
			if( a[0] < b[0] ) { return -1; }
			if( a[0] > b[0] ) { return 1; }
			return 0;
		} );

		var tableHead = '';
		var startingMonth = parseInt( fromDate.split('-')[1] ) - 1;
		var startingYear = parseInt( fromDate.split('-')[0] );
		let columns = [ '<th class="category">Categories</th>' ];
		for( var i = 0; i < months; i++ ) {
			var yearLabel = startingYear + parseInt( (startingMonth + i) / 12 );
			columns.push(
				'<th>' +
					this.months[(startingMonth + i) % 12] +
					'<span class="year">' + yearLabel + '</span>' +
				'</th>'
			);
		}
		columns.push( '<th class="total">Total</th>' );

		$( '#categoriesTable table' ).html( '<tr>' + columns.join('') +'</tr>' );

		var tableHtml = '';
		for( var i = 0; i < tableData.length; i++ ) {
			var row = tableData[ i ];

			var cells = [];
			cells.push( '<td class="cell category">' + row[0] + '</td>' );
			for( var j = 1; j < row.length - 1; j++ ) {
				cells.push( '<td class="cell">' + this.cleanFloat(row[j], 100) + '</td>' );
			}
			var rounded = this.cleanFloat( row[row.length - 1], 10 );
			cells.push( '<td class="cell total">' + rounded + '</td>' );

			var rowClass = i % 2 == 0 ? 'even' : 'odd';
			tableHtml += '<tr class="' + rowClass + '">' + cells.join('') + '</tr>';
		}

		$( '#categoriesTable table' ).append( tableHtml );
	}

	buildBonusesTable() {
		let tableHtml = '<tr>' +
			'<th>Dealer Name</th>' +
			'<th>YTD Tons</th>' +
			'<th>Reward per Ton</th>' +
			'<th>YTD Reward</th>' +
			'<th>Tons to Next Tier</th>' +
			'<th>Next Tier Rewards</th>' +
		'</tr>';

		var dealer = this.selectParentDealer();
		var discount = 0;

		if( dealer.ytd_tons >= 200 ) {
			discount = parseInt( dealer.ytd_tons / 100 );
			discount = Math.min( discount, 10 );
		}
		var savings = discount * parseFloat( dealer.ytd_tons );

		var tonsToNextTier = 0;
		var nextTierSavings = 0;
		if( dealer.ytd_tons < 200 ) {
			tonsToNextTier = this.cleanFloat( 200 - dealer.ytd_tons );
			nextTierSavings = '$' + (2 * (tonsToNextTier + dealer.ytd_tons)) +
				' - $' + (3 * (tonsToNextTier + dealer.ytd_tons) - 2)
		} else if( dealer.ytd_tons < 1000 ) {
			tonsToNextTier = this.cleanFloat( 100 - (dealer.ytd_tons % 100) );
			nextTierSavings = (discount + 1) * (tonsToNextTier + dealer.ytd_tons);
			nextTierSavings = '$' + ((discount + 1) * (tonsToNextTier + dealer.ytd_tons)) +
				' - $' + ((discount + 2) * (tonsToNextTier + dealer.ytd_tons) - 2)
		} else {
			tonsToNextTier = 'N/A'
			nextTierSavings = 'N/A'
		}

		tableHtml += '<tr class="even">' +
			'<td class="name" style="position: static">' + dealer.name + '</td>' +
			'<td>' + dealer.ytd_tons + '</td>' +
			'<td>$' + discount + '.00</td>' +
			'<td>$' + this.cleanFloat(savings) + '</td>' +
			'<td>' + tonsToNextTier + '</td>' +
			'<td>' + nextTierSavings + '</td>' +
		'</tr>';

		$( '#bonusesTable table' ).html( tableHtml );
	}

	buildCategoriesGraph() {
		var categories = [];
		var totals = [];

		for( var i = 0; i < this.dataPoints.length; i++ ) {
			var datapoint = this.dataPoints[ i ];

			if( !this.inDateRange(datapoint.date) ) {
				continue;
			}

			var index = categories.indexOf( datapoint.category );
			if( index < 0 ) {
				categories.push( datapoint.category );
				index = categories.length - 1;
			}

			if( totals.length <= index ) {
				totals.push( 0 );
			}

			totals[ index ] += datapoint.tons;
		}

		// Clean up horrible floating point values, leaving 1 decimal of precision
		var dataMax = 0;
		var maxYCoord = 50;
		for( var i = 0; i < totals.length; i++ ) {
			totals[ i ] = this.cleanFloat( totals[i], 10 );
			if( totals[i] > dataMax ) {
				dataMax = totals[ i ];
			}
		}

		// Abstract all columns less than 10% of the overall total into an "Other" coumn
		var minCutoff = dataMax * 0.1;
		var indicesToRemove = [];
		for( var i = totals.length - 1; i >= 0; i-- ) {
			if( totals[i] < minCutoff ) {
				indicesToRemove.push( i );
			}
		}

		var removedSum = 0;
		if( indicesToRemove.length ) {
			for( var i = 0; i < indicesToRemove.length; i++ ) {
				categories.splice( indicesToRemove[i], 1 );
				removedSum += totals.splice( indicesToRemove[i], 1 )[ 0 ];
			}
		}

		if( removedSum > 0 ) {
			categories.push( 'Other' );
			totals.push( removedSum );
		}

		categories.sort( function(a, b) {
			var i1 = categories.indexOf(a);
			var i2 = categories.indexOf(b);

			return totals[i1] < totals[i2] ? 1 : (totals[i1] == totals[i2] ? 0 : -1);
		} );
		totals.sort( function(a, b) {
			return a < b ? 1 : (a == b ? 0 : -1);
		} );

		if( dataMax >= maxYCoord ) {
			maxYCoord = undefined;
		}

		if( this.categoriesGraph ) {
			this.categoriesGraph.destroy();
		}

		var ctx = document.getElementById( 'categoriesGraph' );
		if( window.outerWidth < 800 ) {
			ctx.setAttribute( 'height', 500 );
		}
		this.categoriesGraph = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: categories,
				datasets: [{
					label: 'Total by Category',
					data: totals,
					backgroundColor: '#FCB614',
					borderColor: '#FCB614',
					borderWidth: 3,
				}]
			},
			options: {
				legend: {
					labels: {
						boxWidth: 7,
						padding: 20,
						usePointStyle: true
					}
				},
				scales: {
					y: {
						ticks: {
							min: 0,
							max: maxYCoord
						}
					}
				}
			}
		})
	}

	openTonnageGoalForm() {
		this.goalBlockTarget.classList.add( 'showForm' );
	}

	closeTonnageGoalForm() {
		this.goalFormSubmitTarget.removeAttribute( 'disabled' );

		this.goalBlockTarget.classList.remove( 'showForm' );
	}

	submitGoalForm( event ) {
		event.preventDefault();
		const form = event.currentTarget;

		fetch( form.getAttribute('action'), {
			method: 'PATCH',
			body: new FormData( form ),
			headers: {
				'Accept': 'application/json'
			}
		} )
		.then( resp => resp.json() )
		.then( json => {
			this.goalTarget.textContent = json.goal_tonnage

			this.closeTonnageGoalForm();
		} )

		return false;
	}
};
