import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	connect() {
		this.connectTabs();

		this.connectSearchBar();
	}

	connectSearchBar() {
		const searchButton = document.querySelector( '#search button' );

		if( searchButton ) {
			searchButton.addEventListener( 'click', this.onSearchClick );
		}
	}

	onSearchClick( e ) {
		const searchInputs = document.querySelectorAll( '#search input' );
		if( searchInputs ) {
			let newLocation = [];
			for( let i = 0; i < searchInputs.length; i++ ) {
				newLocation.push( searchInputs[i].name + '=' + searchInputs[i].value );
			}
			document.location = '?' + newLocation.join('&');
		}
	}

	connectTabs() {
		const tabs = document.querySelectorAll( '#tabs .tab' );
		console.log( tabs );
		tabs.forEach( ele => ele.addEventListener('click', this.onTabClick) );
	}

	onTabClick( e ) {
		const clicked = e.target.dataset.tab;
		const tabs = document.querySelectorAll( '#tabs .tab' );
		const tabbedContent = document.querySelectorAll( '#tabbedContent div' );

		console.log( tabbedContent.children );
		// Toggle Active on the tab and the tabbed content
		tabs.forEach( ele => ele.classList.toggle( 'active', ele.dataset.tab == clicked ) );
		tabbedContent.forEach( ele => ele.classList.toggle( 'active', ele.id == clicked ) );
	}
}
