import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static debounce = null;
	static scrollObserver = null;
	static infiniteScrollDebounce = null;

	connect() {
		this.attachInfiniteScroll();
	}

	reloadForm() {
		document.getElementById( 'excludeIds' ).value = '';

		this.loadResults();
	}

	loadMoreResults() {
		const lastResultCount = parseInt( document.getElementById('lastResultCount').value );
		if( lastResultCount == 12 ) {
			this.loadResults();
		}
	}

	loadResults() {
		this.infiniteScrollDebounce = clearTimeout( this.infiniteScrollDebounce );

		let form = document.getElementById( 'productSearchForm' );
		if( form.requestSubmit ) {
			form.requestSubmit();
		} else {
			form.submit();
		}
	}

	searchPress() {
		if( this.debounce ) {
			clearTimeout( this.debounce );
		}
		this.debounce = setTimeout( () => this.reloadForm(), 350 );
	}

	onTabClick() {
		this.openTab( event.target.dataset.target );
	}

	openTab( tabName ) {
		const newContent = document.querySelectorAll( '.productShowPage .tabbed .' + tabName );
		const prevContent = document.querySelectorAll( '.productShowPage .tabbed .active' );

		// Remove .active from all of the other tabs and content divs
		if( prevContent && prevContent.length ) {
			for( let i = 0; i < prevContent.length; i++ ) {
				prevContent[ i ].classList.remove( 'active' );
			}
		}

		// Set the tab + content divs as .active
		for( let i = 0; i < newContent.length; i++ ) {
			newContent[ i ].classList.add( 'active' );
		}
	}

	openResourceTab() {
		this.openTab( 'resources' );

		document.getElementById('tabs').scrollIntoView({
			behavior: 'smooth'
		});
	}

	attachInfiniteScroll() {
		if( this.scrollObserver || !document.getElementById('resultsEnd') ) {
			return false;
		}

		this.scrollObserver = new IntersectionObserver( this.intersectionDebounce.bind(this), {
			threshold: 1.0
		} );

		this.scrollObserver.observe( document.getElementById('resultsEnd') );
	}

	intersectionDebounce( entries, observer ) {
		if( entries[0].isIntersecting && !this.infiniteScrollDebounce ) {
			this.infiniteScrollDebounce = setTimeout( this.loadMoreResults.bind(this), 150 );
		}
	}
}
