import { Controller } from "@hotwired/stimulus";
import select2 from 'select2';

export default class Ads extends Controller {
	connect() {
		this.connectDealerSelect();
	}

	connectDealerSelect() {
		const $dealerSelect = $( '#dealerSelect' );
		if( $dealerSelect && $dealerSelect.length ) {
			$dealerSelect.select2();
			$dealerSelect.on( 'select2:select', this.onDealerChange );
		}
	}

	onDealerChange( e ) {
		window.location.search = '?dealer=' + e.target.value;
	}

	showAd( e ) {
		console.log( e );
		var $adPreview = $( '#adPreview' );

		$adPreview.find( 'img' ).attr( 'src', $(e.target).attr('data-url') );
		$adPreview.show();
	}

	closePreview() {
		$( '#adPreview' ).hide().attr( 'src', null );
	}

	filterSpeciesAds() {
		event.preventDefault();

		const button = event.currentTarget;
		const species = button.dataset.species;

		if( button.classList.contains('selected') ) {
			// Deselecting a filter
			button.classList.remove( 'selected' );

			$( '.adSlickOption' ).show();
		} else {
			// Selecting a filter
			$( '#filterButtons .selected' ).removeClass( 'selected' );

			button.classList.add( 'selected' );

			if( species == 'all' ) {
				$( '.adSlickOption' ).show();
			} else {
				$( '.adSlickOption' ).hide();
				$( '.adSlickOption[data-species=' + species +']' ).show();
			}
		}
	}
}
