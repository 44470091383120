import { Controller } from "@hotwired/stimulus";

export default class Gro extends Controller {
	connect() {
		if( document.getElementById('groIndex') ) {
			this.setupIndexPageActions();
		}


		if( document.getElementById('groNew') ) {
			this.setupNewPageActions();
		}
	}

	setupIndexPageActions() {
		$('.expandable').on("click", function(){
			$(this).parents(".open_project_block").children('.small_block').fadeOut("fast");
			$(this).parents(".open_project_block").children('.large_block').fadeIn("fast");
		})

		$('.collapsible').on("click", function(){
			console.log($(this).parents(".open_project_block"))
			$(this).parents(".open_project_block").children('.small_block').fadeIn("fast");
			$(this).parents(".open_project_block").children('.large_block').fadeOut("fast");
		})

		$('#dealerSelect').select2();
		$('#dealerSelect').on('select2:select', function (e){
			console.log($(this).val());
			var href = new URL(window.location.href );
			href.searchParams.set('dealer', $(this).val());
			window.location.href = href
		})
	}

	setupNewPageActions() {
		$('#gro_request_materials_needed_true').on("click", function(){
			$('.marketing_block').slideDown("fast" );
		})

		$('#gro_request_materials_needed_false').on("click", function(){
			$('.marketing_block').slideUp();
		})

		$('#gro_request_estimated_project_cost').on("focus", function(){
			$(this).select();
		})

		$('#gro_request_estimated_project_cost').on("keyup", function() {
			if( $(this).val() != '' ){
				$("#r_amount").text("$"+$(this).val());
			} else {
				$("#r_amount").text("$0");
			}
		});
	}
}
