import { Controller } from "@hotwired/stimulus";
import select2 from 'select2';

export default class Tonnage extends Controller {
	static values = {
		// Passed in from the HTML
		pgId: String
	};

	currentDealer = null;

	connect() {
		var $dealerSelect = $( '#dealerSelect' );
		if( $dealerSelect.length ) {
			$dealerSelect.select2();
			$dealerSelect.on( 'select2:select', this.onDealerChange.bind(this) );
		}

		// Auto-load the initial dealer
		this.onDealerChange( null, this.pgIdValue );
	}

	saveProducts() {
		var productIds = new Set();
		var $selectedItems = $( '#form input:checked' );
		for( var i = 0; i < $selectedItems.length; i++ ) {
			productIds.add(
				$( $selectedItems[i] ).attr('id').replace(/\D+/, '')
			)
		}

		$.ajax( '/portal/dealer_products/' + this.currentDealer, {
			method: 'PUT',
			data: {
				products: Array.from(productIds)
			},
			complete: this.onSaveComplete
		} );
	}

	onSaveComplete() {
		$( '.save p' ).html( 'Save successful!' );
	}

	onDealerChange( eventData, pgDealerId ) {
		var selectedPGDealerId = pgDealerId || $( '#dealerSelect option:selected' ).attr( 'value' );
		if( !selectedPGDealerId ) {
			return false;
		}

		this.currentDealer = pgDealerId || selectedPGDealerId;

		$.ajax( '/portal/dealer_products/' + this.currentDealer, {
			method: 'GET',
			complete: this.onDataLoad.bind(this)
		} );
	}

	onDataLoad( response, status ) {
		if( !response || !response.responseJSON ) {
			return false;
		}

		this.listProducts( response.responseJSON.products );
		this.populateDealerProducts( response.responseJSON.dealer_products );
		this.updateSaveButton();
	}

	listProducts( products ) {
		var $header = $( '#header.template' ).clone().removeClass('template').attr('id', '');
		var $item = $( '#item.template' ).clone().removeClass('template').attr('id', '');

		var html = [];
		var product = null;
		var lastProduct = null;
		for( var i = 0; i < products.length; i++ ) {
			if( product ) {
				lastProduct = product;
			}

			product = products[ i ];

			if( i == 0 || products[i - 1].species != product.species ) {
				if( i > 0 ) {
					// Begin new species sections by closing the column of the previous one
					html.push( '</div>' );
				}

				var header = $header.clone();
				$header.find( 'h3' ).html( product.species )

				html.push( '<div class="inputSection col-12 col-md-6">' );
				html.push( $header.html() );
			}

			var $productRow = $item.clone();
			$productRow.find( '.formItem' ).attr( 'data-name', product.name.toLowerCase() );
			$productRow.find( 'label' ).html( product.name );
			$productRow.find( 'label' ).attr( 'for', 'product_' + product.id );
			$productRow.find( 'input' ).attr( 'id', 'product_' + product.id );
			$productRow.find( 'input' ).attr( 'data-species', product.species );
			$productRow.find( 'input' ).attr( 'data-action', 'change->dealer_products#updateSaveButton' );


			html.push( $productRow.html() );
		}

		$( '#form' ).html( html.join('') );
	}

	populateDealerProducts( dealer_product_ids ) {
		var inputs = [];
		for( var i = 0; i < dealer_product_ids.length; i++ ) {
			inputs.push( '#product_' + dealer_product_ids[i] );
		}

		$( inputs.join(',') ).prop( 'checked', true );
	}

	onSearchChange( e ) {
		var searchTerm = e.target.value.toLowerCase();

		var toHide = [];
		var toShow = [];

		var $inputs = $( '#form .formItem' );
		for( var i = 0; i < $inputs.length; i++ ) {
			var $input = $( $inputs[i] );

			if( $input.attr('data-name').match( searchTerm ) ) {
				$input.removeClass( 'hide' );
			} else {
				$input.addClass( 'hide' );
			}
		}
	}

	onToggleAllClick( e ) {
		var $inputs = $( e.target ).parents( '.inputSection' ).find( 'input' );
		var check = !$inputs.first().prop( 'checked' );

		$inputs.prop( 'checked', check );
	}

	updateSaveButton() {
		var $selectedProducts = $( '#form input:checked' );
		var productCount = $selectedProducts.length;
		var speciesCount = new Set();

		for( var i = 0; i < $selectedProducts.length; i++ ) {
			speciesCount.add( $($selectedProducts[i]).attr('data-species') );
		}
		speciesCount = speciesCount.size;

		$( '.save p' ).html(
			'We currently carry ' + productCount + ' Poulin Grain products across ' + speciesCount + ' species.'
		);
	}
}
