import { Controller } from "@hotwired/stimulus";
import GhostContentAPI from '@tryghost/content-api';

export default class extends Controller {
	connect() {
		this.loadPosts( '#blogBlocks' );
	}

	loadPosts( selector ) {
		const blogApi = new GhostContentAPI( {
			url: 'https://poulingrain.com/blog',
			key: '6d201de3b24cda2d05ac6582e4',
			version: 'v3'
		} );

		const container = document.querySelector( selector );
		const targetSpecies = container.getAttribute( 'data-blog-target' );
		const template = container.querySelector( '.template' );
		var filterText = {limit:4};

		if( targetSpecies != undefined && targetSpecies != ""){
			filterText = {filter: 'tag:' + targetSpecies, limit:4};
		}

		blogApi.posts.browse(filterText)
		.then( (posts) => {
			if( !posts.length ) {
				container.parents( 'section' ).hide();
				return false;
			}

			for( var i = 0; i < posts.length; i++ ) {
				var blogPost = posts[ i ];

				// Build the post html
				var post = template.cloneNode( true );
				post.classList.remove( 'template' );
				post.querySelector( 'a' ).setAttribute( 'href', blogPost.url );
				post.querySelector( '.cover' ).style.backgroundImage = 'url(' + blogPost.feature_image + ')';
				post.querySelector( '.title' ).textContent = blogPost.title;
				post.querySelector( '.blurb' ).textContent = blogPost.custom_excerpt || blogPost.excerpt;

				container.append( post );
			}
		} )
		.catch( (err) => {
			console.error( err );
		} );

		template.remove();
	}
}
