import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	selectStar() {
		let label = event.target;
		if( event.tagName != 'LABEL' ) {
			label = event.target.closest( 'label' )
		}

		const index = parseInt( label.dataset.index );
		const stars = this.element.querySelectorAll( '.stars input[type=checkbox]' )
		for( var i = 0; i < stars.length; i++ ) {
			if( parseInt(stars[i].dataset.index) <= index ) {
				stars[ i ].checked = true;
			} else {
				stars[ i ].checked = false;
			}
		}

		console.log( this.element.querySelector( '.stars .selection' ) );
		this.element.querySelector( '.stars .selection' ).textContent = index + '/5';
	}

	validateStars() {
		let validated = !!this.element.querySelector( '.stars input[type=checkbox]:checked' );

		if( !validated ) {
			event.preventDefault();
			this.element.querySelector( '.starError' ).style.display = 'block';
		} else {
			this.element.querySelector( '.starError' ).style.display = 'none';
		}
	}
}
