import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	connect() {
		this.attachDealerChangeListener();

		if( this.element.id == 'orderForm' ) {
			this.updateTotals();
		}

		this.element.addEventListener( 'keypress', (event) => {
			if( event.key === 'Enter' || event.keyCode === 13 ) {
				event.preventDefault();
			}
		} );
	}

	attachDealerChangeListener() {
		const dealerPicker = this.element.querySelector( '#dealerSelect' );

		if( dealerPicker ) {
			dealerPicker.addEventListener( 'change', this.onDealerChange );
		}
	}

	onDealerChange( e ) {
		window.location = window.location.pathname + '?dealer=' + e.target.value;
	}

	setPallets( e ) {
		const perPallet = parseInt( e.target.dataset.perPallet ) || 40;
		let numPallets = parseInt( e.target.value );

		if( !numPallets || numPallets < 0 ) {
			numPallets = 0;
		}

		$( e.target ).parents( 'tr' ).find( 'input.quantity' ).val( perPallet * numPallets );

		this.updateTotals();
	}

	setQuantity( e ) {
		const quantity = parseInt( e.target.value );
		const $palletInput = $( e.target ).parents( 'tr' ).find( 'input.pallets' );
		const perPallet = parseInt( $palletInput.attr('data-per-pallet') ) || 40;

		$palletInput.val( parseInt(quantity / perPallet) );

		this.updateTotals();
	}

	updateTotals() {
		let totalWeight = 0;
		let totalPallets = 0;
		let totalQuantity = 0;
		const $rows = $( 'tr.searchable' );

		for( let i = 0; i < $rows.length; i++ ) {
			let $row = $( $rows[i] );
			let quantity = parseInt( $row.find('input.quantity').val() ) || 0;

			totalQuantity += quantity;
			totalPallets += parseInt( $row.find('input.pallets').val() ) || 0;
			totalWeight += quantity * (parseInt($row.find('.weight').html()) || 0);
		}

		console.log( 'updateTotals', totalQuantity, totalWeight, totalPallets );

		$( '#totalWeight' ).html( Math.round(totalWeight / 20) / 100 );
		$( '#totalPallets' ).html( totalPallets );
		$( '#totalQuantity' ).html( totalQuantity );
	}

	clearAll() {
		$( this.element ).find( 'input.pallets, input.quantity' ).val( null );

		this.updateTotals();
	}

	productSearch( e ) {
		const $rows = $( 'tr.searchable' );
		if( !e.target.value ) {
			$rows.show();
			return;
		}

		let filtered = [];

		for( let i = 0; i < $rows.length; i++ ) {
			if( $rows[i].dataset.search.indexOf(e.target.value.toLowerCase()) >= 0 ) {
				filtered.push( '#' + $rows[i].id );
			}
		}

		$rows.hide();
		$( filtered.join(', ') ).show();
	}

	updateShipTo( e ) {
		const selectedChild = e.target.options[ e.target.selectedIndex ];

		$( '#shipToAddr' ).html(
			selectedChild.innerHTML
		);
	}
}
